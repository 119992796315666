import { LoginViewModel } from "@/models/auth";
import { privateAPI, publicAPI } from "@/plugins/axios";

/* TODO@KP: Check out return models and any types */
const LoginAPI = () => {
  const Login = async (data: LoginViewModel): Promise<any> => {
    const res = await publicAPI.post(`/api/Login/Login`, data);
    return Promise.resolve(res.data);
  };

  const ConfirmResetPassword = async (
    email: string,
    password: string,
    confirmPassword: string,
    code: string
  ): Promise<any> => {
    const res = await publicAPI.post(
      `/api/Login/ConfirmResetPassword?email=${email}&password=${password}&confirmPassword=${confirmPassword}&code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const SyncDeviceTokens = async (token: string): Promise<any> => {
    const res = await privateAPI.get(
      `/api/Login/SyncDeviceTokens?token=${token}`
    );
    return Promise.resolve(res.data);
  };

  const ForgotPassword = async (email: string): Promise<any> => {
    const res = await publicAPI.get(
      `/api/Login/ForgotPassword?email=${email}`
    );
    return Promise.resolve(res.data);
  };

  return { Login, ConfirmResetPassword, SyncDeviceTokens, ForgotPassword };
};

const loginAPI = LoginAPI();
export default loginAPI;
