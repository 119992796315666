import { ChatHistory, ChatPrompts } from "@/models/chat";
import { privateAPI, publicAPI } from "@/plugins/axios";

/* TODO@KP: Check out return models and any types */
const LiveChatAPI = () => {
  const GetNotificationCounts = async (userId: string): Promise<number> => {
    const res = await privateAPI.get(
      `/api/LiveChat/GetNotificationCounts?userId=${userId}`
    );
    return Promise.resolve(res.data);
  };

  const SaveChat = async (data: ChatHistory): Promise<any> => {
    const res = await privateAPI.post(`/api/LiveChat/SaveChat`, data);
    return Promise.resolve(res.data);
  };

  const UpdateChatHistory = async (data: ChatHistory): Promise<any> => {
    const res = await privateAPI.post(`/api/LiveChat/UpdateChatHistory`, data);
    return Promise.resolve(res.data);
  };

  const GetChatHistory = async (bookingId: number): Promise<ChatHistory> => {
    const res = await privateAPI.get(
      `/api/LiveChat/GetChatHistory?bookingId=${bookingId}`
    );
    return Promise.resolve(res.data);
  };

  const GetAllPrompts = async (): Promise<ChatPrompts[]> => {
    const res = await privateAPI.get(`/api/LiveChat/GetAllPrompts`);
    return Promise.resolve(res.data);
  };

  return {
    GetNotificationCounts,
    SaveChat,
    UpdateChatHistory,
    GetChatHistory,
    GetAllPrompts,
  };
};

const liveChatAPI = LiveChatAPI();
export default liveChatAPI;
