<template>
  <v-app id="app">
    <ToastHandler></ToastHandler>
    <router-view></router-view>
  </v-app>
</template>

<script lang="ts" setup>
import ToastHandler from "@/components/ToastHandler.vue";
import { onMounted } from "vue";
import { useNotificationStore } from "./store/notifications";
import { Device } from "@capacitor/device";
import { useProfileStore } from "./store/profile";
import { useFirebaseHook } from "./utils/firebase";
import { storageLib } from "./utils/storage";
import { useNotificationHook } from "./utils/notifications";
import logger from "./utils/logger";
import { useAuthenticationStore } from "./store/authentication";

const notifStore = useNotificationStore();
const authStore = useAuthenticationStore();
const profileStore = useProfileStore();
const firebaseHook = useFirebaseHook();
const notificationHook = useNotificationHook();

onMounted(async () => {
  const devInfo = await Device.getInfo();
  notifStore.deviceInfo = devInfo;
  const id = await Device.getId();

  if (profileStore.getProfile() && authStore.authenticated == true) {
    const token = await firebaseHook.getPWAToken();

    if (token != null) {
      await storageLib.setFcmToken(token);
      notifStore.setFirebaseToken(token);
      logger.log(
        "Firebase Token: ",
        token,
        "devInfo: ",
        devInfo,
        "deviceId: ",
        id
      );
      await notificationHook.doPushNotifications(token);
    }
  }
});
</script>
